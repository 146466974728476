exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutzbestimmung-tsx": () => import("./../../../src/pages/datenschutzbestimmung.tsx" /* webpackChunkName: "component---src-pages-datenschutzbestimmung-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-neues-passwort-tsx": () => import("./../../../src/pages/neues-passwort.tsx" /* webpackChunkName: "component---src-pages-neues-passwort-tsx" */),
  "component---src-pages-nutzungsbedingungen-tsx": () => import("./../../../src/pages/nutzungsbedingungen.tsx" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-tsx" */),
  "component---src-pages-passwort-vergessen-tsx": () => import("./../../../src/pages/passwort-vergessen.tsx" /* webpackChunkName: "component---src-pages-passwort-vergessen-tsx" */),
  "component---src-pages-zugang-beantragen-tsx": () => import("./../../../src/pages/zugang-beantragen.tsx" /* webpackChunkName: "component---src-pages-zugang-beantragen-tsx" */)
}

